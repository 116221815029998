<template>
    <div>
        <div class="card">
            <div class="card-body">
                <div v-if="loading" class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i></div>
                <template v-else>
                    <div class="alert alert-info text-center">Поочередно сканируйте ШК паллет, которые хотите добавить в
                        накладную
                    </div>
                    <div class="alert alert-light">
                        <div class="container">
                            <div class="row">
                                <div class="col text-left">
                                    Всего паллет: {{ pallets.length }}
                                </div>
                                <div v-if="pallets.length && shipmentDate" class="col text-right">
                                    Дата отгрузки: {{ shipmentDate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-for="pallet in pallets"
                         @dblclick="unReservePallet(pallet)"
                         :key="pallet.id"
                         class="alert alert-dark mb-1">
                        <span>{{ pallet.name }}</span>
                        <span v-if="pallet.loading" class="pull-right"><i class="fas fa-spinner fa-spin"></i></span>
                    </div>
                    <div class="mb-3"></div>
                </template>
            </div>

            <control-panel v-if="pallets.length">
                <button @click="createAct()" class="btn btn-success">Сформировать накладную</button>
            </control-panel>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import {SHIPMENT_ACT_ENDPOINT, STOCK_ENDPOINT} from '@utils/endpoints';
import ControlPanel from '@components/_common/ControlPanel';
import Barcode from '@utils/Barcode';
import {findIndex} from 'lodash';

export default {
    name: 'DctShipment',
    components: {
        ControlPanel
    },
    data() {
        return {
            loading: false,
            pallets: [],
        };
    },
    computed: {
        shipmentDate() {
            const pallets = this.pallets.filter(pallet => !!pallet.shipment_date);
            if (pallets.length > 0) {
                const pallet = pallets[0];
                return moment(pallet.shipment_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
            }
            return null;
        }
    },
    methods: {
        onBarcodeScanned(data) {
            if (data.barcode.startsWith(Barcode.palletBarcodePrefix())) {
                this.palletBarcodeScanned(data.barcode);
                return;
            }

            this.$bvToast.toast('Неверный тип ШК', {variant: 'danger'});
        },
        load() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.$http.get(STOCK_ENDPOINT + `/pallet/tmp`, {
                params: {
                    without_loading: true
                }
            }).then(response => {
                this.pallets = response.data;
            }).catch(response => {
                this.$bvToast.toast(response.data.message, {variant: 'danger'});
            }).finally(() => {
                this.loading = false;
            });
        },
        palletBarcodeScanned(barcode) {
            this.reservePallet(barcode);
        },
        reservePallet(barcode) {
            if (this.palletBeingReserved) {
                this.$bvToast.toast('Дождитесь завершения прерыдущей операции', {variant: 'info'});
                return;
            }

            this.palletBeingReserved = true;
            this.$http.post(STOCK_ENDPOINT + `/attach_pallet`, {
                pallet_code: barcode,
            }, {
                params: {without_loading: true}
            }).then(response => {
                this.deleteFromList(response.data);
                this.pallets.unshift(response.data);
            }).catch(response => {
                this.$bvToast.toast(response.data.message, {variant: 'danger'});
            }).finally(() => {
                this.palletBeingReserved = false;
            });
        },
        unReservePallet(pallet) {
            if (!!pallet.loading) {
                return;
            }

            if (!window.confirm('Удалить паллет из списка?')) {
                return;
            }

            pallet.loading = true;
            this.$forceUpdate();
            this.$http.post(STOCK_ENDPOINT + `/detach_pallet`, {
                pallet_code: pallet.code
            }, {
                params: {without_loading: true}
            }).then(() => {
                this.deleteFromList(pallet);
            }).catch(response => {
                this.$bvToast.toast(response.data.message, {variant: 'danger'});
            });
        },
        deleteFromList(pallet) {
            let index = findIndex(this.pallets, p => p.id === pallet.id);
            if (index !== -1) {
                this.pallets.splice(index, 1);
            }
        },
        createAct() {
            if (!window.confirm('Отгрузить выбранные паллеты?')) {
                return;
            }

            let pallets = this.pallets.map(p => p.id);
            this.$http.post(SHIPMENT_ACT_ENDPOINT + '/create_by_pallets', {
                pallets: pallets
            }).then(() => {
                this.$bvToast.toast('Накладная сформирована', {variant: 'sucess'});
                this.load();
            }).catch(response => {
                this.$bvToast.toast(response.data.message, {variant: 'danger'});
            });
        }
    },
    mounted() {
        this.load();
        this.$root.listenToBarcodeScanning(this);
    }
}
</script>
